// Generated by Framer (450d190)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bq6HknN3R", "SHaao7P84"];

const serializationHash = "framer-M0dww"

const variantClassNames = {bq6HknN3R: "framer-v-17ktq1m", SHaao7P84: "framer-v-7zfj31"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "bq6HknN3R", Inactive: "SHaao7P84"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bq6HknN3R"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "bq6HknN3R", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17ktq1m", className, classNames)} data-framer-name={"Active"} layoutDependency={layoutDependency} layoutId={"bq6HknN3R"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-51fbaa80-2371-42f7-947f-8d22e2db73a1, rgb(18, 18, 23))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, opacity: 1, ...style}} variants={{SHaao7P84: {backgroundColor: "rgba(22, 22, 24, 0.75)", opacity: 0.25}}} {...addPropertyOverrides({SHaao7P84: {"data-framer-name": "Inactive"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M0dww.framer-u9u0w8, .framer-M0dww .framer-u9u0w8 { display: block; }", ".framer-M0dww.framer-17ktq1m { height: 4px; overflow: visible; position: relative; width: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 4
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SHaao7P84":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW4z9cbC0Y: React.ComponentType<Props> = withCSS(Component, css, "framer-M0dww") as typeof Component;
export default FramerW4z9cbC0Y;

FramerW4z9cbC0Y.displayName = "Bullet Selector";

FramerW4z9cbC0Y.defaultProps = {height: 4, width: 4};

addPropertyControls(FramerW4z9cbC0Y, {variant: {options: ["bq6HknN3R", "SHaao7P84"], optionTitles: ["Active", "Inactive"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerW4z9cbC0Y, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})